import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
	domain = "";

  	constructor(private db: AngularFireDatabase) {
		if(window.location.hostname == environment.skyDomain){
			this.domain = 'SkyStreamEvents'
		}
		else if(window.location.hostname == environment.tune2Domain){
			this.domain = 'Tune2'
		}
		else{
			this.domain = 'Pyrocast'
		}
   	}

  getData(showCode: any): Observable<any> {
		return this.db.object(this.domain + '/Shows/' + showCode + '/Message')
    .valueChanges()
  }

	setData(showCode: any, value: any): Promise<void> {
		return this.db.object(this.domain + '/Shows/' + showCode + '/Message').update(value);
	}

	setTimeData(showCode: any, value: any): Promise<void> {
		return this.db.object(this.domain + '/Times/' + showCode + '/' + (+ new Date()) ).update(value);
	}

	getShowStatus(showCode: any): Observable<any> {
		return this.db.object(this.domain + '/ShowsCall/' + showCode + '/call')
			.valueChanges()
	}

	setShowStatus(showCode: any, value: any): Promise<void> {
		return this.db.object(this.domain + '/ShowsCall/' + showCode ).update({
			call: value
		});
	}
	getNotifications(app: string): Observable<any> {
			return this.db.object('/Ignite/Notification/' + app)
		.valueChanges()
	}
	addNotification(platform: string, node: string, payload: any): Promise<void>{
		return this.db.object('/Ignite/Notification/'+ platform +'/' + node).update(payload);
	}

	deleteNotification(platform: string, node: string): Promise<void>{
		return this.db.object('/Ignite/Notification/'+ platform +'/' + node).remove();
	}
}
