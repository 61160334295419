import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public socket: WebSocket;

  constructor() {
    // Connect to the WebSocket server
    this.socket = new WebSocket('wss://beta.api.pyrocast.com/ws');

    // Listen for messages from the server
    // this.socket.onmessage = (event) => {
    //   const data = JSON.parse(event.data);
    //   console.log('Timestamp from server:', data.timestamp);
    // };

    // Handle WebSocket errors
    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Handle WebSocket connection closing
    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }
}