<h2 class="popup-heading" mat-dialog-title>Reporting</h2>
<mat-table [dataSource]="reportingSource">
    <ng-container matColumnDef="parameter">
      <mat-header-cell *matHeaderCellDef> Parameter </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.parameter}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.details}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  
<mat-dialog-actions align="end">
    <button mat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="download-report" (click)="downloadCsv()">Download Report</button>
    <button class="cancel-popup" mat-stroked-button (click) = "close()">Close</button>
</mat-dialog-actions>