import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ShowsService} from "../services/shows.service";
import {DatePipe} from "@angular/common";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {interval} from "rxjs";
import {DatabaseService} from "../services/database.service";
import { ActivatedRoute } from '@angular/router';
import { Show } from '../interfaces/show.interface';
import {environment} from "../../environments/environment";
import {Font} from "ngx-font-picker";

import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";

@Component({
  selector: 'app-single-show',
  templateUrl: './single-show.component.html',
  styleUrls: ['./single-show.component.scss']
})
export class SingleShowComponent implements OnInit, AfterViewInit {

  	public processing: boolean;

	public show!:Show;
	public brand: any = null;
	public font: any = new Font();
	public fileInfo:any;
	public fileChanged = false;
	public loaded = false;
	public progress = 0;
	public retried = 0
	public loadingError = false
	public fileError = false
	public newAudioFile = false;
	public timeDifference = 0;
	public timeOffset = ((new Date()).getTimezoneOffset() + (this.isDST() ? 60 : 0) )*60*1000;
	public seekTime = new Date(this.timeOffset);
	public secondsCounter = interval(1000);
	public subscription:any;
	public countdown = 0;
	public adjustment = 0;
	public globalAdjustment = 0;
	public defaultAdjustment = 0;
	public updatingShow = false;
	public timeout:any = null;
	public interactionAllowed = true;
	public interacting = false;
	public played = false;
	public isPublic = false;
	public show_code: any;
	public audioLength = 0;
	public isPyrocast = true;
	public siteName = "Pyrocast"
	public isTune2 = false;
	public listening = false;
	public random = 0;
	public timestampStartTime = 0;
	public timestampStartTime2 = 0;
	public wave: WaveSurfer | undefined;
	buttonVisible = true;
	@ViewChild('audio') audioElement:ElementRef | undefined;
	announcementComment: string = '';
	  

  	constructor(
		private showService: ShowsService,
		private cdr: ChangeDetectorRef,
		public datepipe: DatePipe,
		private db: DatabaseService,
		private route: ActivatedRoute,
		public dialog: MatDialog) {
		if(window.location.hostname == environment.skyDomain){
			this.isPyrocast = false;
			this.siteName = 'SkyStreamEvents'
		}
		if(window.location.hostname == environment.tune2Domain){
			this.isPyrocast = false;
			this.isTune2 = true;
			this.siteName = 'Tune2'
		}		
		this.processing = false;
		this.font.family = 'Roboto';
		this.random = Math.random();			
	}
	formatTime(time: string): string {
		let [hours, minutes] = time.split(':');
		let period = 'AM';
	  
		let hoursNumber = parseInt(hours, 10); // Parse hours as a number
	  
		if (hoursNumber >= 12) {
		  period = 'PM';
		  hoursNumber = hoursNumber > 12 ? hoursNumber - 12 : hoursNumber;
		} else if (hoursNumber === 0) {
		  hoursNumber = 12;
		}
	  
		return `${hoursNumber}:${minutes} ${period}`;
	}	  

	isDST() {
		let d = new Date();
		let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
		let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
		return Math.max(jan, jul) !== d.getTimezoneOffset();
	}

  	ngOnInit(): void {
		if(window.localStorage.getItem('adjustment')){
			this.globalAdjustment = Number(window.localStorage.getItem('adjustment'));
		}
		let tsValues = {
			T1_Client: 0,
			T2_Server: 0,
			T3_Client: 0,
			T4_Server: 0,
			T5_Client: 0,
			RTT: 0,
			T_to: 0
		};
		this.timestampStartTime = (+ new Date());
		tsValues.T1_Client = this.timestampStartTime;
		this.showService.getTimestamp().subscribe(response => {
			tsValues.T2_Server = (+new Date(response.timestamp));
			
			this.timestampStartTime -= (+ new Date());
			this.timestampStartTime2 = (+ new Date());
			tsValues.T3_Client = this.timestampStartTime2
			this.showService.getTimestamp().subscribe(response => {
				tsValues.T4_Server = response.timestamp
				this.timestampStartTime2 -= (+ new Date());
				tsValues.T5_Client = (+ new Date());
				tsValues.RTT = tsValues.T5_Client - tsValues.T1_Client
				tsValues.T_to = (tsValues.T2_Server - tsValues.T1_Client + tsValues.T4_Server - tsValues.T3_Client) / 2
				console.log(tsValues);
				this.db.setTimeData(this.show_code, tsValues);
				console.log(((this.timestampStartTime+this.timestampStartTime2)/2) + " milliseconds");
				console.log((+(new Date())  - this.timeDifference ) % 1000);
				let ts = (+ new Date());
				this.timeDifference = ts - response.timestamp + (this.timestampStartTime+this.timestampStartTime2)/2;
				this.timeDifference = tsValues.T_to;
				console.log(this.timeDifference + " milliseconds");
				// setTimeout(() => {
				// 	this.secondsCounterFunc();
				// }, (+(new Date())  - this.timeDifference ) % 1000);
				//if(this.timeDifference)
				console.log("Time Difference is: " + this.timeDifference);
				//this.timeDifference = 0;
				//console.log(this.timeDifference);
			});
			this.timeDifference = (+ new Date() - response.timestamp);
			console.log(this.timeDifference = (+ new Date() - response.timestamp))
		});
		// this.showService.getTimestamp().subscribe(response => {
		// 	let ts = (+ new Date());
		// 	this.timeDifference = ts - response.timestamp;
		// 	console.log(this.timeDifference);
		// });
		this.subscription = this.secondsCounter.subscribe(n => {
			if(this.loaded){
				this.showService.getShowJson(this.show).subscribe(data => {
					const status = data.split(',');
					if(status[0] === 'play' || status[0] === 'resume'){
						if(!this.wave?.isPlaying()){
							let t = Number(status[2]);
							const playTime = (+ new Date()) - Number(status[1]);
							if(this.wave && this.wave.getDuration()){
								if(!this.show.looping && ((+ new Date()) - Number(status[1])) > 0 && ((+ new Date()) - Number(status[1])) < this.audioLength*1000 || this.show.looping && Number(status[1]) < (+ new Date())){
									let seekTime = (+(new Date()) - Number(status[1]) + Number(status[2])) % (this.audioLength*1000)
									this.wave.setTime((seekTime - this.timeDifference)/1000);
									this.wave.play()
								}
								else{
									this.show.status = "stop";
								}
							}
						}
						else{
							
						}
					}
					else if(status[0] == 'pause'){
						this.played = true;
						if(!this.interacting){
							this.wave?.seekTo(((status[2] - this.timeDifference) / 1000 + this.adjustment + this.globalAdjustment + this.defaultAdjustment) / this.audioLength);
						}
						if(this.wave?.isPlaying()) {
							this.wave?.pause()
						}
						else{
						}
					}
					else{
						if(this.wave?.getCurrentTime() && !this.interacting){
							this.wave?.stop()
						}
						else{
						}
					}
				})
			}
		});
  	}

  	ngAfterViewInit(): void {
    	this.route.params.subscribe(params => {
      		if(params['show_code']){
        		this.show_code = params['show_code'].toUpperCase();
				this.showService.getSingleShow( this.show_code ).subscribe((response:any) => {
				this.db.getData(this.show_code).subscribe((response: any) => {
					if (response && response.messageText) {
						this.announcementComment = response.messageText;
					}
				});
				if(response) {
					this.show = response;
					if(this.show.brand){
						this.brand = this.show.brand
						this.font.family = this.brand.font_family;
					}
					if(this.show.created_date){
					this.show.created_date_ts = new Date(this.show.created_date.split(' ')[0] + "T" + this.show.created_date.split(' ')[1] + "+00:00");
					}
					if(this.show.show_date){
						this.show.show_date_ts = new Date(this.show.show_date.split(' ')[0] + "T" +this.show.show_date.split(' ')[1] + "+00:00");
					}
					this.show.audio_file_name = response.audio_file_info.file_name;
					this.loadFileInfo();
				}
				}, (error) => {
					this.loaded = true;
				});
      		}
    	});
  	}
  	userGesture(){
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'confirm-play',
			maxWidth: '600px',
			width: '95%',
			data: {
				title: 'Audio Player',
				message: 'Show is started and will be played in the on your phone.',
			}
		});
		dialogRef.componentInstance.onConfirm.subscribe(result =>{
			if(result){
				dialogRef.close();
				
			}
		});
	}
	versionedImage(url: string){
		return url + "?v=" + Math.random();
	}
	ngOnDestroy() {
		if(this.subscription){
			this.subscription.unsubscribe();
		}
	}
  	loadFileInfo() {
		if(this.show?.audio_file_id && this.show?.audio_file_name){
			this.showService.getFileInfo(this.show?.audio_file_id, this.show).subscribe((response:any) => {
				this.fileInfo = response;
				this.audioLength = Number(this.fileInfo.file_duration)
			}, () => {
				if(this.retried <= 6){
					this.retried++;
					setTimeout(() => {
						this.loadFileInfo();
					}, 5000)
				}
				else{
					this.loadingError = true;
				}
			})
		}
	}
	init(){
		this.previewStart();
		this.buttonVisible = false;
	}
	previewStart(): void {
		if (!this.wave) {
			this.generateWaveform();
		}

		this.cdr.detectChanges();

		Promise.resolve().then(() => {
			if(this.wave) {
				this.wave.load((this.fileInfo.timecode_channel === 'right' ? this.fileInfo.timecode_file_url : this.fileInfo.file_url))//+'?v='+Math.random()
			}
		});
	}

	generateWaveform(): void {
		Promise.resolve(null).then(() => {
			this.wave = WaveSurfer.create({
				container: '#waveform',
				waveColor: this.isTune2 ? '#DE072A' : (!this.isPyrocast ? '#0f57c6' : '#2cbcf4'),
				progressColor: this.isTune2 ? '#830008' : (!this.isPyrocast ? '#03238a' : '#03238a'),
				interact: false,
				plugins: [
					TimelinePlugin.create({
						container: '#wave-timeline'
					})
				]
			});
			this.wave.on('loading', (progress) => {
				this.progress = progress
			});
			this.wave.on('ready', () => {
				if (this.wave) {
					this.loaded = true;
					this.cdr.detectChanges();
				}
			});
			this.wave.on('play', () => {
				this.played = true;
			});
			this.wave.on('finish', () => {
				if(this.show && !this.show.looping){
					this.show.status = "stop";
				}
				else{
					this.wave?.play();
				}
			});
		});
	}

	adjust(add: boolean){
		this.adjustment += (add ? 1 : -1) * 0.1;
		if(this.wave && this.wave.getCurrentTime() > 0){
			this.wave.skip((add ? 1 : -1) * 0.1);
		}
	}
}
