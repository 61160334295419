import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {Show} from "../interfaces/show.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ShowsService {
  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/shows/available', { //?email=ssmith@cobrafiringsystems.com
      params: params
    });
  }

  	getFileInfo(file_id: string, show: Show): Observable<Object>{
		return this.http.get(show.platform  == 'AUDIO_APP' ? environment.apiUrl + '/audio/info/' + file_id : environment.apiUrl + '/audio/info/shows/' + show.show_code);
	}

	getSingleShow(show_code: string): Observable<Object>{
		return this.http.get(environment.apiUrl + '/shows/'+show_code);
	}

	store(show: Show): Observable<Object> {
		return this.http.post(environment.apiUrl + '/shows', show);
	}

	clone(show: Show): Observable<Object>{
		return this.http.post(environment.apiUrl + '/shows/copy/' + show.show_id, {});
	}

	edit(show_id:string, show: any): Observable<Object> {
		return this.http.put(environment.apiUrl + '/shows/'+show_id, show);
	}

	getReport(show_id:string): Observable<Object> {
		return this.http.get(environment.apiUrl + '/shows/report/'+show_id);
	}

	uploadAudio(formData: any): Observable<any>{
		return this.http.post('https://audio-api.cobrashowcreator.com/audio/upload', formData, {
			reportProgress: true,
			observe: 'events'
		})
	}

	updateAudioFile(show_id: any, audio_file_id: any): Observable<any> {
		return this.http.put(environment.apiUrl + '/shows/' + show_id + '/audio/' + audio_file_id, {})
	}

	uploadVideo(show: any, video_url: any, platform: any, filename: string): Observable<any>{
		console.log(show);
		const formData = new FormData();
		formData.append("video_url", video_url);
		formData.append("filename", filename);
		formData.append("platform", platform);
		return this.http.post(environment.apiUrl + '/shows/'+show.show_id+'/video', formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	deleteVideo(platform: any, id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/shows/'+platform+'/'+id+'/video');
	}

	updateShowJson(show: Show, data: string): Observable<Object> {
		return this.http.post(environment.apiUrl + '/playback/shows/' + show.show_code + '/status', {status_content: data})
  	//return this.http.get( 'http://hotshotapis-env.s8bggapbtx.us-east-1.elasticbeanstalk.com/hotshotapis/cobraregistration/update-show.php?userid=0&showid=' + show.show_code + '&jsonData=&websiteData=' + data);
	}

	getShowJson(show: Show): Observable<any> {
  	return this.http.get('https://d3p78baftr7q1p.cloudfront.net/' + show.show_code + '.txt?v=' + Math.random(), { responseType: 'text' as const });
	}

	getSecurityCode(show_id: any, platform: any): Observable<Object> {
		return this.http.get(environment.apiUrl + '/shows/'+platform+'/'+show_id+'/security-code');
	}

	hide(show_id:any): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/shows/'+show_id+'/hide', {});
	}

	unhide(show_id:any): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/shows/'+show_id+'/unhide', {});
	}
	getShowReport(show_id:any): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/shows/'+show_id+'/report', {});
	}

	getTimestamp(): Observable<any> {
		return this.http.get(environment.apiUrl + '/playback/timestamp');
	}
	addSMPTE(file_id: any, platform: any, smpte_offset: any): Observable<any>{
		return this.http.put('https://audio-api.cobrashowcreator.com/audio/files/audiosync/' + file_id, {
			'timecode_channel': 'right',
			'source': platform,
			'music_channel_start_delay': Number(smpte_offset)
		})
	}
	removeSMPTE(file_id: any, platform: any): Observable<any>{
		return this.http.put('https://audio-api.cobrashowcreator.com/audio/files/audiosync/' + file_id, {
			'timecode_channel': 'none',
			'source': platform
		})
	}
	getAppUrl(show: Show){
		return this.http.get(environment.apiUrl + '/shows/'+show.show_id+'/link?domain=' + window.location.hostname)
	}

	getFingerprints(start_date: any, end_date: any, wesbite_id: any): Observable<any> {
		return this.http.post(environment.apiUrl + '/shows/fingerprints', {
		  'start_date': start_date,
		  'end_date': end_date,
		  'website_id': wesbite_id // This will now send either all platform IDs or just the selected one
		});
	}
	getFingerprintDownloadToken(show_code: any): Observable<any>{
		return this.http.get(environment.apiUrl + '/shows/fingerprints/download-token/' + show_code)
	}
	destroy(id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/shows/'+id);
	}
}
